/* .login-form {
  max-width: 300px;
}
.login-form-forgot {
  float: right;
}
.ant-col-rtl .login-form-forgot {
  float: left;
} */
.login-form-button {
  width: 100%;
}

.form {
  position: absolute;
  top: 45%;
  left: 50%;
  margin: -160px 0 0 -160px;
  width: 320px;
  height: 320px;
  padding: 36px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);
}

.form .button {
  width: 100%;
}

.form p {
  color: rgb(204, 204, 204);
  text-align: center;
  margin-top: 16px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}

.logo {
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo img {
  width: 40px;
  margin-right: 8px;
}

.logo span {
  vertical-align: text-bottom;
  font-size: 16px;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 700;
  /* color: @primary-color;
    .text-gradient(); */
}

.ant-spin-container,
.ant-spin-nested-loading {
  height: 100%;
}

.footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
}
